import React from 'react';
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {SubmitHandler, useForm} from "react-hook-form";
import {init, send} from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import {Inputs} from "../../components/Inputs";
init("user_jEHI0s5U4vAgfNI3DrChd");

const Request = () => {
  const {register, handleSubmit, formState: { errors } }: any = useForm<Inputs>();
  let captcha = false;

  function onChange (value : string | null) {
    if (Boolean(value)) captcha = true;
  }

  const onTimeout = () => captcha = false;

  const onSubmit: SubmitHandler<Inputs> = (d) => {
    if (!captcha) return alert("Bitte fülle das Captcha aus");

    send(
      'service_1yepwtk',
      'template_k58zcb9',
      d,
      'user_jEHI0s5U4vAgfNI3DrChd'
    )
      .then((response) => {
        alert("Email erfolgreich versendet");
      })
      .catch((err) => {
        alert("Es gab einen Fehler beim senden der Email");
      });
  }

  return (
    <article className="wrapper">
      <NavBar current="contact"/>
      <div className="mx-auto lg:text-left lg:w-full lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <h1 className="text-2xl">Anfrage</h1>
        <br/>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col cards w-full">
          <p>Das interessiert mich, ich hätte Interesse an:</p>
          <label className="text-gray-700">
            <input type="checkbox" {...register("interest1")} value="ja"/>
            <span className="ml-1">an einem Beratungsgespräch mit einem Leasingberater</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" {...register("interest2")} value="ja"/>
            <span className="ml-1">an einem unverbindlichem Angebot</span>
          </label>
          <br/>

          <p>Ich bin</p>
          <label className="text-gray-700">
            <input type="radio" {...register("iAm", { required: true })} value="Geschäftskunde"/>
            <span className="ml-1">Geschäftskunde</span>
          </label>

          <label className="text-gray-700">
            <input type="radio" {...register("iAm", { required: true })} value="Privatkunde"/>
            <span className="ml-1">Privatkunde</span>
          </label>
          {errors.iAm && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Interesse</p>
          <p>Anschaffungswert in Euro</p>
          <input className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="number"  {...register("valueEuro", { required: true })} placeholder="Wert in Euro"/>
          {errors.valueEuro && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Ich interessiere mich für folgende Angebote der Leasing GmbH oder deren Kooperationspartner:</p>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("nutzfahrzeug")} />
            <span className="ml-1">Nutzfahrzeuge</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("auflieger")}/>
            <span className="ml-1" >Auflieger/Anhänger</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("pkw")} />
            <span className="ml-1">PKW</span>
          </label>
          <br/>

          <p>Genaue Bezeichnung</p>
          <textarea  {...register("desc", { required: true })} className="w-full lg:w-3/4 h-16 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" />
          {errors.desc && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>KM Laufleistung</p>
          <input  {...register("laufleistung", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.laufleistung && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Vertragsart:</p>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("l_operate")} />
            <span className="ml-1">operate-lease</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("l_restwert")} />
            <span className="ml-1">Leasing mit Restwert</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("l_vollamortisation")} />
            <span className="ml-1">Leasing mit Vollamortisation</span>
          </label>
          <label className="text-gray-700">
            <input type="checkbox" value="ja" {...register("l_mietkauf")} />
            <span className="ml-1">Mietkauf</span>
          </label>
          <br/>

          <p>Ich wünsche Angebote über folgende Laufzeiten</p>
          <label className="text-gray-700">
            <input type="radio" name="time" value="24" {...register("time", { required: true })} />
            <span className="ml-1">24 Monate</span>
          </label>
          <label className="text-gray-700">
            <input type="radio" name="time" value="36" {...register("time", { required: true })} />
            <span className="ml-1">36 Monate</span>
          </label>
          <label className="text-gray-700">
            <input type="radio" name="time" value="42" {...register("time", { required: true })} />
            <span className="ml-1">42 Monate</span>
          </label>
          <label className="text-gray-700">
            <input type="radio" name="time" value="48" {...register("time", { required: true })} />
            <span className="ml-1">48 Monate</span>
          </label>
          <label className="text-gray-700">
            <input type="radio" name="time" value="54" {...register("time", { required: true })} />
            <span className="ml-1">54 Monate</span>
          </label>
          <label className="text-gray-700">
            <input type="radio" name="time" value="60" {...register("time", { required: true })} />
            <span className="ml-1">60 Monate</span>
          </label>
          {errors.time && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Sonderzahlung</p>
          <input  {...register("sonderzahlung", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.sonderzahlung && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>in...</p>
          <label className="text-gray-700">
            <input  {...register("sonderzahlungIn", { required: true })} type="radio" name="sonderzahlungIn" value="Euro"/>
            <span className="ml-1">€</span>
          </label>

          <label className="text-gray-700">
            <input  {...register("sonderzahlungIn", { required: true })} type="radio" name="sonderzahlungIn" value="Prozent"/>
            <span className="ml-1">%</span>
          </label>
          {errors.sonderzahlungIn && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Restwert</p>
          <input {...register("restwert", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.restwert && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>in...</p>
          <label className="text-gray-700">
            <input {...register("restwertIn", { required: true })} type="radio" name="restwertIn" value="Euro"/>
            <span className="ml-1">€</span>
          </label>

          <label className="text-gray-700">
            <input {...register("restwertIn", { required: true })} type="radio" name="restwertIn" value="Prozent"/>
            <span className="ml-1">%</span>
          </label>
          {errors.restwertIn && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Mitteilung</p>
          <textarea  {...register("mitteilung", { required: true })} className="w-full lg:w-3/4 h-16 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" />
          {errors.mitteilung && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Anrede</p>
          <div className="relative inline-block w-full lg:w-3/4 text-gray-700">
            <select {...register("anrede")}
                    className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline"
              placeholder="Auswählen...">
              <option>Herr</option>
              <option>Frau</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd" fillRule="evenodd" />
              </svg>
            </div>
          </div>
          <br/>

          <p>Vorname</p>
          <input {...register("vorname", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.vorname && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Nachname</p>
          <input  {...register("nachname", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.nachname && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Firma</p>
          <input  {...register("firma")} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          <br/>

          <p>Postleitzahl/Ort</p>
          <input  {...register("ort", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          {errors.ort && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Straße/Hausnummer</p>
          <input  {...register("hausnr")} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          <br/>

          <p>Email Adresse</p>
          <input  {...register("email", { required: true })} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" />
          {errors.email && <span className="error">Dieses Feld muss ausgefüllt werden</span>}
          <br/>

          <p>Telefon</p>
          <input  {...register("telefon")} className="w-full lg:w-3/4 h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
          <br/>

          <ReCAPTCHA
            sitekey="6LfSe3MdAAAAABe5bosBujTvo42E3l3eD-kZpLxX"
            onChange={onChange}
            onExpired={onTimeout}
          />
          <br/>

          <button type="submit" className="h-10 w-full lg:w-3/4 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            Absenden
          </button>

        </form>
      </div>
      <Footer/>
    </article>
  );
};

export default Request;